import React, { useState } from 'react';
import Header from './Header';
import Hero from './Hero';
import About from './About';
import WhyUs from './WhyUs';
import Skills from './Skills';
import Footer from './Footer';
// import './App.css';

function App() {
  const [isAdminHovered, setIsAdminHovered] = useState(false);

  // Function to handle admin hover
  const handleAdminHover = () => {
    setIsAdminHovered(true);
  };

  // Function to handle admin hover out
  const handleAdminHoverOut = () => {
    setIsAdminHovered(false);
  };

  // Function to handle edit action
  const handleEdit = () => {
    // Implement your edit functionality here
    console.log('Editing...');
  };

  return (
    <div className=''>
      <main id="main" onMouseEnter={handleAdminHover} onMouseLeave={handleAdminHoverOut}>
      <Header />
        <Hero />
        <About />
        <WhyUs />
        <Skills />
      </main>
      <Footer />
    </div>
  );
}

export default App;
