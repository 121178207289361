import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import winner1 from '../../../assets/img/winner1.jpg';
import winner2 from '../../../assets/img/winner2.jpg';
import winner3 from '../../../assets/img/winner3.jpg';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../features/auth/api';
import { apiEndpoint } from '../../../config/constant';
import { Slide, toast } from 'react-toastify';
import { SketchPicker } from 'react-color';
import ToastContent from '../../../HealperComponents/toast';

Modal.setAppElement('#root');

const SkillsSection = () => {

  const { data, status, error } = useSelector((state) => state.HomePageInfo);

  const [title, setTitle] = useState('Top Winners of This Week');
  const [description, setDescription] = useState(
    'It is a long established fact that a reader will be distracted by the readable.'
  );
  const [images, setImages] = useState([winner1, winner2, winner3]);
  const [newImages, setNewImages] = useState([null, null, null]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [textColor, setTextColor] = useState('#ffffff');
  const [headingColor, setHeadingColor] = useState('#ffffff');

  useEffect(() => {
    if(data){
      const value = JSON.parse(data[0]?.sectionFour);
      setImages(value?.slide)
      setTitle(value?.content?.title);
      setDescription(value?.content?.description);
      setTextColor(value?.content?.textColor);
      setHeadingColor(value?.content?.headingColor);
    }

  },[data])


  const handleImageChange = async (index, e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('files', file);
    try {
      const response = await axiosInstance.post(apiEndpoint.UPLOADIMAGES, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const updatedSlides = [...images];
      updatedSlides[index] = response?.data?.data?.imageURL;
      setImages(updatedSlides);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleSave = async () => {
   const data = {
    content : {
      title,
      description,
      headingColor,
      textColor
    },
    slide : images
   }

    try {
      await axiosInstance.post( apiEndpoint.UPDATESECTIONFOUR, data ,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
              toast.success(<ToastContent message={"Save Successfull"} status="success" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
      setModalIsOpen(false);
    } catch (err) {
      console.error(err);
      alert('Failed to save changes');
    }
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <section id="skills" className="py-12">
      <div className=" mx-auto">
        <div className="text-center">
          <h3 className="text-3xl font-bold text-white" style={{ color: headingColor }}>{title}</h3>
          <p className="text-lg text-white"  style={{ color: textColor }}>{description}</p>
          <div className="mt-3">
            <button onClick={openModal} className="get-started-btn2 borderp-5 hover:opacity-30  font-bold text-white">Edit</button>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-5">
          {images.map((image, index) => (
            <div key={index} className="flex justify-center">
              <img src={image} alt={`Winner ${index + 1}`} className="max-w-full h-auto" />
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Skills Section"
        className="modal-content h-[70%]"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-xl font-bold mb-4">Edit Skills Section</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="text-input mb-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Title color</label>
          <SketchPicker
            color={headingColor}
            onChangeComplete={(color) => setHeadingColor(color.hex)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="text-input mb-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Title color</label>
          <SketchPicker
            color={headingColor}
            onChangeComplete={(color) => setTextColor(color.hex)}
          />
        </div>
        {[0, 1, 2].map((index) => (
          <div className="mb-4" key={index}>
            <label className="block text-sm font-medium text-gray-700">{`Winner Image ${index + 1}`}</label>
            <input
              type="file"
              onChange={(e) => handleImageChange(index, e)}
              className="file-input mt-4"
            />
          </div>
        ))}
        <button onClick={handleSave} className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Save</button>
        <button onClick={closeModal} className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Cancel</button>
      </Modal>
    </section>
  );
};

export default SkillsSection;
