import React from 'react';
import { Link } from 'react-router-dom';
import { handleLogout } from '../../config/function';
import { motion } from 'framer-motion';
import { FaTimes } from 'react-icons/fa';
import { HiOutlineHome, HiOutlineLogout } from 'react-icons/hi';
import { RiMoneyDollarBoxLine, RiPieChartLine, RiUserLine, RiEditBoxLine , RiGameLine} from 'react-icons/ri';

const Sidebar = ({ isActive, setIsActive }) => {
  function handleToggle() {
    setIsActive(!isActive);
  }

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: isActive ? 250 : 0 }}
      transition={{ duration: 0.3 }}
      className="fixed top-0 left-0 bg-gray-900 text-white h-full shadow-lg overflow-hidden z-40"
      style={{ width: isActive ? 250 : 0 }}
    >
      <span onClick={handleToggle} className="absolute top-4 right-4 text-white p-2 cursor-pointer">
        {isActive && <FaTimes size={20} />}
      </span>
      {isActive && (
        <>
          <div className="flex items-center justify-center h-16 bg-blue-500">
            <h1 className="text-2xl font-bold">Admin Panel</h1>
          </div>
          <nav className="flex-1 p-4">
            <ul>
              <li className="mb-4 text-center hover:bg-slate-500 flex items-center justify-center">
                <HiOutlineHome className="mr-2" size={20} />
                <Link to="/dashboard" className="text-lg hover:text-primary">
                  Home
                </Link>
              </li>
              <li className="mb-4 text-center hover:bg-slate-500 flex items-center justify-center">
                <RiMoneyDollarBoxLine className="mr-2" size={20} />
                <Link to="/withdrawal-requests" className="text-lg hover:text-primary">
                  Withdrawal Requests
                </Link>
              </li>
              <li className="mb-4 text-center hover:bg-slate-500 flex items-center justify-center">
                <RiMoneyDollarBoxLine className="mr-2" size={20} />
                <Link to="/deposits" className="text-lg hover:text-primary">
                  Deposits
                </Link>
              </li>
              <li className="mb-4 text-center hover:bg-slate-500 flex items-center justify-center">
                <RiUserLine className="mr-2" size={20} />
                <Link to="/active-users" className="text-lg hover:text-primary">
                  Active Users
                </Link>
              </li>
              <li className="mb-4 text-center hover:bg-slate-500 flex items-center justify-center">
                <RiGameLine className="mr-2" size={20} />
                <Link to="/game-setting" className="text-lg hover:text-primary">
                  Game Setting
                </Link>
              </li>
              <li className="mb-4 text-center hover:bg-slate-500 flex items-center justify-center">
                <RiPieChartLine className="mr-2" size={20} />
                <Link to="/statistical-analysis" className="text-lg hover:text-primary">
                  Statistical Analysis
                </Link>
              </li>
              <li className="mb-4 text-center hover:bg-slate-500 flex items-center justify-center">
                <RiEditBoxLine className="mr-2" size={20} />
                <Link to="/website-editing" className="text-lg hover:text-primary">
                  Website Editing
                </Link>
              </li>
            </ul>
          </nav>
          <div className="flex mb-10 justify-center">
            <button
              onClick={handleLogout}
              className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-gradient-to-br from-red-600 to-red-400 group-hover:from-red-600 group-hover:to-red-400 focus:ring-4 focus:outline-none focus:ring-red-200"
            >
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gray-900 rounded-md group-hover:bg-opacity-0 flex items-center">
                <HiOutlineLogout className="mr-2" size={20} />
                Logout
              </span>
            </button>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default Sidebar;
