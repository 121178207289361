import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import slide from '../../../assets/img/slide.png';
import { SketchPicker } from 'react-color';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../features/auth/api';
import { Slide, toast } from 'react-toastify';
import { apiEndpoint } from '../../../config/constant';
import ToastContent from '../../../HealperComponents/toast';

Modal.setAppElement('#root');

const HeroSection = () => {
  const [editMode, setEditMode] = useState(false);
  const { data, status, error } = useSelector((state) => state.HomePageInfo);

  const [slides, setSlides] = useState([
    { heading: '', rewardText: '', image: slide, textColor: '#ffffff', headingColor: '#ffffff', fontSize: '24px', fontStyle: 'normal' },
    { heading: '', rewardText: '', image: slide, textColor: '#ffffff', headingColor: '#ffffff', fontSize: '24px', fontStyle: 'normal' },
    { heading: '', rewardText: '', image: slide, textColor: '#ffffff', headingColor: '#ffffff', fontSize: '24px', fontStyle: 'normal' },
  ]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useEffect(() => {
    setSlides(JSON.parse(data[0]?.sectionOne));
  }, [data[0]?.sectionOne]);

  const handleSlideChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('files', file);

    try {
      const response = await axiosInstance.post(apiEndpoint.UPLOADIMAGES, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const updatedSlides = [...slides];
      updatedSlides[activeSlideIndex].image = response?.data?.data?.imageURL;
      setSlides(updatedSlides);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleSave = async () => {
    try {
      await axiosInstance.post(apiEndpoint.UPDATESECTIONONE, slides);
              toast.success(<ToastContent message={"Save Successfull"} status="success" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });

      setModalIsOpen(false);
    } catch (err) {
      console.error(err);
      alert('Failed to save changes');
    }
  };

  const openModal = (index) => {
    setActiveSlideIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => setModalIsOpen(false);

  const handleSlideEditChange = (e) => {
    const { name, value } = e.target;
    const updatedSlides = [...slides];
    updatedSlides[activeSlideIndex][name] = value;
    setSlides(updatedSlides);
  };

  const handleColorChange = (color, name) => {
    const updatedSlides = [...slides];
    updatedSlides[activeSlideIndex][name] = color.hex;
    setSlides(updatedSlides);
  };

  return (
    <section className="relative flex items-center justify-center group">
      <div className="container mx-auto">
        <Carousel
          autoPlay
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          showIndicators={true}
        >
          {slides.map((slide, index) => (
            <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div className="text-center">
                <p className="text-white" style={{ color: slide.textColor, fontSize: slide.fontSize, fontStyle: slide.fontStyle }}>
                  {slide.rewardText}
                </p>
                <h2 className="text-2xl font-bold" style={{ color: slide.headingColor, fontSize: slide.fontSize, fontStyle: slide.fontStyle }}>
                  {slide.heading}
                </h2>
                {/* <div className="mt-4">
                  <a href="#" className="btn btn-get-started">Get Started</a>
                </div> */}
              </div>
              <div className="text-center">
                <img src={slide?.image} alt="PVP Casino" className="max-w-full h-auto" />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <button
        className="absolute top-0 right-0 m-4 px-4 py-2 bg-black text-white rounded opacity-0 transition-opacity duration-300 group-hover:opacity-100"
        onClick={() => openModal(activeSlideIndex)}
      >
        Edit
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Slide"
        className="modal-content h-full  overflow-auto"
        overlayClassName="modal-overlay"
      >
        <div className="grid grid-cols-1 md:grid-cols-2  gap-8">
          <div>
            <h2 className="text-xl font-bold mb-4">Edit Slide</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Select Slide</label>
              <select
                value={activeSlideIndex}
                onChange={(e) => setActiveSlideIndex(Number(e.target.value))}
                className="text-input mb-2"
              >
                {slides.map((_, index) => (
                  <option key={index} value={index}>
                    Slide {index + 1}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Reward Text</label>
              <input
                type="text"
                name="rewardText"
                value={slides[activeSlideIndex].rewardText}
                onChange={handleSlideEditChange}
                className="text-input mb-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Heading</label>
              <input
                type="text"
                name="heading"
                value={slides[activeSlideIndex].heading}
                onChange={handleSlideEditChange}
                className="text-input mb-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Text Color</label>
              <SketchPicker
                color={slides[activeSlideIndex].textColor}
                onChangeComplete={(color) => handleColorChange(color, 'textColor')}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Heading Color</label>
              <SketchPicker
                color={slides[activeSlideIndex].headingColor}
                onChangeComplete={(color) => handleColorChange(color, 'headingColor')}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Font Size</label>
              <input
                type="text"
                name="fontSize"
                value={slides[activeSlideIndex].fontSize}
                onChange={handleSlideEditChange}
                className="text-input mb-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Font Style</label>
              <select
                name="fontStyle"
                value={slides[activeSlideIndex].fontStyle}
                onChange={handleSlideEditChange}
                className="text-input mb-2"
              >
                <option value="normal">Normal</option>
                <option value="italic">Italic</option>
                <option value="bold">Bold</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Slide Image</label>
              <input
                type="file"
                onChange={handleSlideChange}
                className="file-input mt-4"
              />
            </div>
            <button
              className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
          <div className="preview-section">
            <h2 className="text-xl font-bold mb-4">Preview</h2>
            <div className="preview-slide text-center">
              <p className="text-white" style={{ color: slides[activeSlideIndex].textColor, fontSize: slides[activeSlideIndex].fontSize, fontStyle: slides[activeSlideIndex].fontStyle }}>
                {slides[activeSlideIndex].rewardText}
              </p>
              <h2 className="text-2xl font-bold" style={{ color: slides[activeSlideIndex].headingColor, fontSize: slides[activeSlideIndex].fontSize, fontStyle: slides[activeSlideIndex].fontStyle }}>
                {slides[activeSlideIndex].heading}
              </h2>
              <div className="mt-4">
                <img src={slides[activeSlideIndex].image} alt="PVP Casino" className="max-w-full h-auto" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default HeroSection;
