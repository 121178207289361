import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import axiosInstance from '../../features/auth/api';
import { apiEndpoint } from '../../config/constant';
import JoditEditor from 'jodit-react';
import { Slide, toast } from 'react-toastify';

import ToastContent from '../../HealperComponents/toast';

Modal.setAppElement('#root');

const GameEdit = () => {
  const [games, setGames] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentGame, setCurrentGame] = useState(null);
  const editor = useRef(null);
	const [content, setContent] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    minPlayers: '',
    maxPlayers: '',
    description: '',
    gameRules: '',
    minimumBet: '',
    image: '',
    status: 'inactive'
  });
  const [imageUpload, setImageUpload] = useState(null);

  useEffect(() => {
    axiosInstance.get(apiEndpoint.FetchGameData)
      .then(response => {
        setGames(response.data.data);
      })
      .catch(error => {
        console.error('There was an error fetching the game data!', error);
      });
  }, []);

  const openModal = (game) => {
    setCurrentGame(game);
    setFormData({
      title: game.title,
      minPlayers: game.minPlayers,
      maxPlayers: game.maxPlayers,
      description: game.description,
      minimumBet: game.minimumBet,
      image: game.image,
      status: game.status
    });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentGame(null);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (name === 'image' && files) {
      setImageUpload(files[0]);
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value
      });
    }
  };

  console.log(content);

  const uploadImage = async (imageFile) => {
    const formData = new FormData();
    formData.append('files', imageFile);

    const response = await axiosInstance.post(apiEndpoint.UPLOADIMAGES, formData,{
        headers: {
            'Content-Type': 'multipart/form-data'
          }
    }); 
    return response.data.data.imageURL;
  };

  const handleSave = async () => {
    let imageUrl = formData.image;
    if (imageUpload) {
      imageUrl = await uploadImage(imageUpload);
    }

    const updatedData = { ...formData, image: imageUrl, gameRule: content};

    axiosInstance.put(`${apiEndpoint.SETGAMES}${currentGame.id}`, updatedData)
      .then(response => {
        setGames(games.map(game => game.id === currentGame.id ? response.data.data : game));
        toast.success(<ToastContent message={"Save Successfull"} status="success" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
        closeModal();
      })
      .catch(error => {
        toast.error(<ToastContent message={error.message} status="success" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
        console.error('There was an error updating the game!', error);
      });
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Edit Games</h2>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 bg-gray-200 font-semibold text-left">Game Name</th>
            <th className="py-2 px-4 bg-gray-200 font-semibold text-left">Min Players</th>
            <th className="py-2 px-4 bg-gray-200 font-semibold text-left">Max Players</th>
            <th className="py-2 px-4 bg-gray-200 font-semibold text-left">Min Bet</th>
            <th className="py-2 px-4 bg-gray-200 font-semibold text-left">Image</th>
            <th className="py-2 px-4 bg-gray-200 font-semibold text-left">Active</th>
            <th className="py-2 px-4 bg-gray-200 font-semibold text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {games.map(game => (
            <tr key={game.id} className="border-b">
              <td className="py-2 px-4">{game.title}</td>
              <td className="py-2 px-4">{game.minPlayers}</td>
              <td className="py-2 px-4">{game.maxPlayers}</td>
              <td className="py-2 px-4">{game.minimumBet}</td>
              <td className="py-2 px-4">
                <img src={game.image} alt={game.title} width="50" className="rounded" />
              </td>
              <td className="py-2 px-4">{game.status === 'active' ? 'Yes' : 'No'}</td>
              <td className="py-2 px-4">
                <button
                  className="bg-blue-500 text-white px-2 py-1 rounded"
                  onClick={() => openModal(game)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal isOpen={modalIsOpen}  onRequestClose={closeModal}>
        <div className="p-6 z-50">
          <h2 className="text-xl font-bold mb-4">Edit Game</h2>
          <form className="space-y-4">
            <label className="block">
              <span className="text-gray-700">Game Name:</span>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className="block w-full mt-1 p-2 border border-gray-300 rounded"
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Min Players:</span>
              <input
                type="number"
                name="minPlayers"
                value={formData.minPlayers}
                onChange={handleInputChange}
                className="block w-full mt-1 p-2 border border-gray-300 rounded"
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Game Rules:</span>
              <JoditEditor
			ref={editor}
			value={content}
			tabIndex={1} // tabIndex of textarea
			onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
			onChange={newContent => {}}
		/>
            </label>
            <label className="block">
              <span className="text-gray-700">Max Players:</span>
              <input
                type="number"
                name="maxPlayers"
                value={formData.maxPlayers}
                onChange={handleInputChange}
                className="block w-full mt-1 p-2 border border-gray-300 rounded"
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Description:</span>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                className="block w-full mt-1 p-2 border border-gray-300 rounded"
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Min Bet:</span>
              <input
                type="number"
                name="minimumBet"
                value={formData.minimumBet}
                onChange={handleInputChange}
                className="block w-full mt-1 p-2 border border-gray-300 rounded"
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Image:</span>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleInputChange}
                className="block w-full mt-1 p-2 border border-gray-300 rounded"
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Active:</span>
              <input
                type="checkbox"
                name="status"
                checked={formData.status === 'active'}
                onChange={() =>
                  setFormData({ ...formData, status: formData.status === 'active' ? 'inactive' : 'active' })
                }
                className="ml-2"
              />
            </label>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={handleSave}
                className="bg-green-500 text-white px-4 py-2 rounded"
              >
                Save
              </button>
              <button
                type="button"
                onClick={closeModal}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default GameEdit;
