import { createContext, useEffect } from "react";
import axiosInstance from "../features/auth/api";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import ToastContent from "../HealperComponents/toast";
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  { fetchingHomePageInfo }  from "../features/auth/fetchingWebsiteInfo";
import { useDispatch } from "react-redux";


export const DataContext = createContext();

export const AllData = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginApi = async (data) => {
    axiosInstance
      .post("/admin/admin-login", data)
      .then((res) => {
        const expirationTime = 1;
        Cookies.set('token', res.data.data.token, { expires: expirationTime });
        toast.success(<ToastContent message={"Login successful"} status="success" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
        window.location.replace('/dashboard');
        console.log(res, "response");
      })
      .catch((err) => {
        toast.error(<ToastContent message={"Login failed"} status="error" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
        console.log(err);
      });
  }

  const verifyToken = async () => {
    axiosInstance
      .post("/admin/verify-admin")
      .then((res) => {
        if (res.data.status) {
          navigate('/dashboard');
        } else {
          navigate('/');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  useEffect(() => {
    dispatch(fetchingHomePageInfo());
  }, [dispatch]);
  // useEffect(() => 
  //   {
  //     fetchingHomePageInfo();
  //   },[]);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      verifyToken();
    } else {
      navigate('/');
    }
  }, []);

  return (
    <DataContext.Provider value={{ loginApi }}>
      {children}
    </DataContext.Provider>
  );
}
