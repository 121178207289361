import React, { useEffect, useState } from 'react';
import axiosInstance from '../../features/auth/api';

const WithdrawalRequestPage = () => {

  const [withdrwalHistory,setWithdrawalHistory] = useState();



    const approvedByadmin = async (data) => {
      try {
        const response = await axiosInstance.post('/admin/withdrawal-aprrove',data);
        if(response.data.status){
          alert(response.data.message)
        }
       
      } catch (err) {
        console.error(err);
      }
    };
 

  
  useEffect(() => {
    const fetchWithdrawalHistory = async () => {
      try {
        const response = await axiosInstance.post('/withdrawal-request');
        console.log(response)
        setWithdrawalHistory(response.data.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchWithdrawalHistory();
  }, []);

  return (
    <div className="min-h-screen bg-background text-white p-4">
      <h1 className="text-4xl font-bold text-primary mb-4">Withdrawal Requests</h1>
      <div className="bg-gray-800 p-4 rounded">
        <table className="w-full text-left">
          <thead>
            <tr>
              <th className="p-2">User Id</th>
              <th className="p-2">Withdrawal Address</th>
              <th className="p-2">Amount</th>
              <th className="p-2">Status</th>
              <th className="p-2">Date</th>
              <th className="p-2">Approve</th>
            </tr>
          </thead>
          <tbody>
               {withdrwalHistory?.map((withdrawal, index) => (
                 <tr key={index}>
                  <td className="px-4 py-2 text-white">{withdrawal.userId}</td>
                  <td className="px-4 py-2 text-white">{withdrawal.withdrwalAdress }</td>
                  <td className="px-4 py-2 text-white">{withdrawal.amount}</td>
                  <td className="px-4 py-2 text-white">{withdrawal.status }</td>
                  <td className="px-4 py-2 text-white">{withdrawal.createdAt}</td>
                  <button onClick={()=>approvedByadmin({assets:withdrawal.assetId,id:withdrawal.userId,withdrwalAdress:withdrawal.withdrwalAdress})} type="button" class="text-white bg-gradient-to-r h-8 from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 text-center me-2 mb-2">Approve</button>
                </tr>
            
          ))}
             </tbody>
        </table>
      </div>
          {withdrwalHistory?.length == 0 && <div className="text-white text-center font-bold text-2xl items-center h-full justify-center"><p>No data found</p></div>}
    </div>
  );
};

export default WithdrawalRequestPage;
