import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import axiosInstance from '../../../features/auth/api';
import { Slide, toast } from 'react-toastify';
import { apiEndpoint } from '../../../config/constant';
import ToastContent from '../../../HealperComponents/toast';

const Header = () => {
  const { data } = useSelector((state) => state.HomePageInfo);
  const [logo, setLogo] = useState(data[0]?.websiteLogo);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tempLogo, setTempLogo] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    uploadLogo(file);
  };

  const handleSave = async () => {
    try {
      const response = await axiosInstance.post(apiEndpoint.UPDATELOGO, { url: tempLogo }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
              toast.success(<ToastContent message={"Save Successfull"} status="success" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
      setLogo(response?.data?.data?.newUrl);
      setModalIsOpen(false);
    } catch (err) {
      console.error(err);
      alert('Failed to upload logo');
    }
  };

  const handleCancel = () => {
    setTempLogo(null);
    setModalIsOpen(false);
  };

  const uploadLogo = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    try {
      const response = await axiosInstance.post(apiEndpoint.UPLOADIMAGES, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setTempLogo(response?.data?.data?.imageURL);
    } catch (err) {
      console.error(err);
      alert('Failed to upload logo');
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center  bg-gradient-to-r from-[#c407f822] via-purple-700 to-[#c407f822] px-4 md:px-32 py-4">
        <div className="relative flex justify-center items-center mb-0 group">
          <img src={logo} alt="Logo" className="h-14 w-auto cursor-pointer" />
          <button
            className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-50 text-white font-medium rounded-lg transition-opacity duration-300 group-hover:opacity-100"
            onClick={() => setModalIsOpen(true)}
          >
            Edit Logo
          </button>
        </div>
        <div className="flex flex-row justify-center items-center gap-4">
          <span className="flex gap-3 justify-center items-center">
            <p className="text-white font-bold cursor-pointer">Casino</p>
          </span>
          <span className="text-white gap-1 cursor-pointer bg-gradient-to-r flex from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
            <span>Deposit</span>
          </span>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCancel}
        contentLabel="Edit Logo Modal"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg shadow-lg w-1/2"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <h2 className="text-2xl font-bold mb-4">Edit Logo</h2>
        <input type="file" onChange={handleFileChange} className="mb-4" />
        {tempLogo && (
          <img src={tempLogo} alt="New Logo" className="h-14 w-auto mb-4" />
        )}
        <div className="flex space-x-4">
          <button
            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-transform transform hover:scale-105"
            onClick={handleSave}
          >
            Save
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-transform transform hover:scale-105"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Header;
