import React, { useState, useEffect } from 'react';
import axios, { formToJSON } from 'axios';
import axiosInstance from '../../../features/auth/api';
import { apiEndpoint } from '../../../config/constant';
import { Slide, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import ToastContent from '../../../HealperComponents/toast';

const AboutUsSection = () => {


  const { data, status, error } = useSelector((state) => state.HomePageInfo);

  useEffect(() => {
    if (data) {
      const value = JSON.parse(data[0]?.sectionTwo);
      setContent(value?.Contents);

      // console.log(value?.Images,'fetching length ');

      setImage1(value?.Images?.image1);


      setImage2(value?.Images?.image2);

      setImage3(value?.Images?.image3);

    }   
  }, [data])


const [image1, setImage1] = useState('');
const [image2, setImage2] = useState('');
const [image3, setImage3] = useState('');
const [content, setContent] = useState([]);
const [showModal, setShowModal] = useState(false);
const [newHeading, setNewHeading] = useState('');
const [newContent, setNewContent] = useState('');
const [headingColor, setHeadingColor] = useState('#ffffff');
const [contentColor, setContentColor] = useState('#ffffff');
const [headingStyle, setHeadingStyle] = useState('normal');
const [contentStyle, setContentStyle] = useState('normal');
const [editIndex, setEditIndex] = useState(null);


const handleImageChange = (event, setImage) => {
  const file = event.target.files[0];
  const formData = new FormData();
  formData.append('files', file);

  axiosInstance.post(apiEndpoint.UPLOADIMAGES, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(response => {
      setImage(response.data.data.imageURL);
    })
    .catch(error => {
      console.error('Error uploading image:', error);
    });
};

const handleEditImage = (event, setImage) => {
  event.preventDefault();
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = 'image/*';
  fileInput.onchange = (e) => handleImageChange(e, setImage);
  fileInput.click();
};

const handleAddContent = () => {
  setEditIndex(null);
  setShowModal(true);
};

const handleSaveContent = () => {
  if (newHeading && newContent) {
    const newContentItem = {
      heading: newHeading,
      content: newContent,
      headingColor,
      contentColor,
      headingStyle,
      contentStyle
    };
    if (editIndex !== null) {
      const updatedContent = [...content];
      updatedContent[editIndex] = newContentItem;
      setContent(updatedContent);
    } else {
      setContent([...content, newContentItem]);
    }
    setNewHeading('');
    setNewContent('');
    setHeadingColor('#ffffff');
    setContentColor('#ffffff');
    setHeadingStyle('normal');
    setContentStyle('normal');
    setShowModal(false);
  } else {
    alert('Please fill out both heading and content fields.');
  }
};

const handleCancelAdd = () => {
  setNewHeading('');
  setNewContent('');
  setHeadingColor('#ffffff');
  setContentColor('#ffffff');
  setHeadingStyle('normal');
  setContentStyle('normal');
  setShowModal(false);
};

const handleEditContent = (index) => {
  setEditIndex(index);
  const item = content[index];
  setNewHeading(item.heading);
  setNewContent(item.content);
  setHeadingColor(item.headingColor);
  setContentColor(item.contentColor);
  setHeadingStyle(item.headingStyle);
  setContentStyle(item.contentStyle);
  setShowModal(true);
};

const handleDeleteContent = (index) => {
  const updatedContent = content.filter((_, i) => i !== index);
  setContent(updatedContent);
};

const handleSaveChanges = () => {
  const data = {
    images: { image1, image2, image3 },
    content
  };

  axiosInstance.post(apiEndpoint.UPDATESECTIONTWO, data)
    .then(response => {
      console.log('Changes saved:', response.data);
     console.log(response.data.status);
     toast.success(<ToastContent message={"Save Successfull"} status="success" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
    })
    .catch(error => {
      console.error('Error saving changes:', error);
    });
};

return (
  <section className="bg-black py-12 border-black hover:border-2 hover:border-[#ffffff22]">
    <div className="text-center md:text-right col-span-3">
      <div className="flex justify-center mb-3">
        <button className="text-white bg-blue-500 rounded px-2 py-1 mr-2" onClick={handleAddContent}>+</button>
        <a href="#" className="text-white get-started-btn2">Add Content</a>
      </div>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center relative">
      <div className="relative flex justify-center flex-wrap">
        <img src={image1} alt="PVP" className="max-w-full h-auto mb-2 md:mb-0" />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition duration-300">
          <div className="text-white text-center">
            <h2 className="mb-2">Image 1 Heading</h2>
            <button className="bg-blue-500 text-white rounded px-2 py-1 mr-2" onClick={(e) => handleEditImage(e, setImage1)}>Edit Image</button>
          </div>
        </div>
      </div>
      <div className="relative flex justify-center">
        <img src={image2} alt="PVP" className="max-w-full h-auto mb-2 md:mb-0" />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition duration-300">
          <div className="text-white text-center">
            <h2 className="mb-2">Image 2 Heading</h2>
            <button className="bg-blue-500 text-white rounded px-2 py-1 mr-2" onClick={(e) => handleEditImage(e, setImage2)}>Edit Image</button>
          </div>
        </div>
      </div>
      <div className="relative flex justify-center">
        <img src={image3} alt="PVP" className="max-w-full h-auto mb-2 md:mb-0" />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition duration-300">
          <div className="text-white text-center">
            <h2 className="mb-2">Image 3 Heading</h2>
            <button className="bg-blue-500 text-white rounded px-2 py-1 mr-2" onClick={(e) => handleEditImage(e, setImage3)}>Edit Image</button>
          </div>
        </div>
      </div>
    </div>

    {showModal && (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-md max-w-lg w-full">
          <h2 className="text-2xl font-bold mb-4">{editIndex !== null ? 'Edit Content' : 'Add New Content'}</h2>
          <input
            type="text"
            placeholder="Heading"
            className="bg-gray-200 px-3 py-2 rounded mb-2 w-full"
            value={newHeading}
            onChange={(e) => setNewHeading(e.target.value)}
          />
          <p className="text-black font-bold">Choose color for heading</p>
          <input
            type="color"
            value={headingColor}
            onChange={(e) => setHeadingColor(e.target.value)}
            className="mb-2"
          />
          <p className="text-black font-bold">Choose style for heading</p>
          <select
            className="bg-gray-200 px-3 py-2 rounded mb-2 w-full"
            value={headingStyle}
            onChange={(e) => setHeadingStyle(e.target.value)}
          >
            <option value="normal">Normal</option>
            <option value="italic">Italic</option>
            <option value="bold">Bold</option>
          </select>
          <textarea
            placeholder="Content"
            className="bg-gray-200 px-3 py-2 rounded mb-2 w-full"
            rows="3"
            value={newContent}
            onChange={(e) => setNewContent(e.target.value)}
          />
          <p className="text-black font-bold">Choose color for content</p>
          <input
            type="color"
            value={contentColor}
            onChange={(e) => setContentColor(e.target.value)}
            className="mb-2"
          />
          <p className="text-black font-bold">Choose style for content</p>
          <select
            className="bg-gray-200 px-3 py-2 rounded mb-2 w-full"
            value={contentStyle}
            onChange={(e) => setContentStyle(e.target.value)}
          >
            <option value="normal">Normal</option>
            <option value="italic">Italic</option>
            <option value="bold">Bold</option>
          </select>
          <button
            className="bg-blue-500 text-white rounded px-4 py-2"
            onClick={handleSaveContent}
          >
            Save
          </button>
          <button
            className="bg-gray-500 text-white rounded px-4 py-2 ml-2"
            onClick={handleCancelAdd}
          >
            Cancel
          </button>
        </div>
      </div>
    )}

    <div className="flex justify-center gap-4 mt-8">
      {content && content.map((item, index) => (
        <div key={index} className=" p-4 rounded shadow-md relative">
          <h2
            className="text-2xl text-center font-bold mb-2"
            style={{ color: item.headingColor, fontStyle: item.headingStyle }}
          >
            {item.heading}
          </h2>
          <p
            className="text-lg"
            style={{ color: item.contentColor, fontStyle: item.contentStyle }}
          >
            {item.content}
          </p>
          <button
            className="bg-green-500 text-white rounded px-2 py-1 mt-2"
            onClick={() => handleEditContent(index)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white rounded px-2 py-1 mt-2 ml-2"
            onClick={() => handleDeleteContent(index)}
          >
            Delete
          </button>
        </div>
      ))}
    </div>

    <div className="text-center mt-8">
      <button
        className="bg-green-500 text-white rounded px-4 py-2"
        onClick={handleSaveChanges}
      >
        Save Changes
      </button>
    </div>
  </section>
);
};

export default AboutUsSection;
