import React, { useEffect, useState } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Modal from 'react-modal';
import axiosInstance from '../../../features/auth/api';
import { apiEndpoint } from '../../../config/constant';
import { Slide, toast } from 'react-toastify';
import ToastContent from '../../../HealperComponents/toast';

// Initialize the Modal
Modal.setAppElement('#root');

export default function Footer() {
  const [facebookLink, setFacebookLink] = useState('https://www.facebook.com');
  const [whatsappLink, setWhatsappLink] = useState('https://wa.me/');
  const [twitterLink, setTwitterLink] = useState('https://twitter.com');
  const [linkedinLink, setLinkedinLink] = useState('https://www.linkedin.com');
  const [socialData, setSocialData] = useState([]);
  const [selectedLink, setSelectedLink] = useState('');

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [id, setID] = useState();
  const [idx, setIdx] = useState(0);
  const [selectedIcon, setSelectedIcon] = useState('');
  const [image, setImage] = useState();
  const [socialName,setsocialName] = useState();

  const handleEdit = (id,name,idx) => {
    setIdx(idx);
    setSelectedIcon(name);
    setID(id);
    setModalIsOpen(true);
    setSelectedLink(socialData[idx]?.socialmediaLinks);
    setsocialName(socialData[idx]?.socialmediaName);
    setImage(socialData[idx]?.socialmediaImgUrl);
  };

  const handleCancel = () => {
    setIdx();
    setSelectedIcon();
    setID();
    setModalIsOpen();
    setSelectedLink();
    setsocialName();
    setImage();
    setModalIsOpen(false)
  };

  console.log(socialData);

  const handleFetchedSocailLink = async ()=>{
    try {
      const response = await axiosInstance.get(apiEndpoint.FETCHSOCIALLINKS);
      setSocialData(response.data.data);
    } catch (error) {
      console.log(error);
      
    }

  }

  // console.log(socialData[0]);

  useEffect(() => {
    handleFetchedSocailLink();
  },[])



  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('files', file);
    try {
      const response = await axiosInstance.post(apiEndpoint.UPLOADIMAGES, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setImage(response?.data?.data?.imageURL);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };


  const handleSave = async() =>{
    try {
      const response = await axiosInstance.post(apiEndpoint.UPDATESOCIALMEDIA ,{
        id,
        socialName,
        selectedLink,
        socialmediaImgUrl: image,
      });
              toast.success(<ToastContent message={"Save Successfull"} status="success" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
      setModalIsOpen(false);
      handleFetchedSocailLink();
    } catch (error) {
      console.error('Error updating social link:', error);
    }



  }



  return (
    <div className="flex flex-col lg:flex-row  bg-gradient-to-r from-[#c407f822] via-purple-700 to-[#c407f822] justify-between w-full px-4 lg:px-32 py-6 lg:py-10">
      <div className="flex flex-col mb-6 lg:mb-0">
        <p className="text-white">
          Copyright © 2024, All rights reserved. Poker
        </p>
        <p className="text-white">
          This game involves an element of financial risk and may be addictive.
          Please play responsibly
        </p>
        <p className="text-white">and at your own risk.</p>
      </div>
      <div className="flex justify-center items-center gap-4 lg:gap-10">
{socialData?.map((value,idx) => {
  return(
    <span
    className="border rounded-full p-2 cursor-pointer hover:bg-black"
    onClick={() => handleEdit(value?.id,value?.socialmediaName,idx)}
  >
    <img src={value?.socialmediaImgUrl} height={40} width={40} alt={value?.id} />
  </span>
  )

})}
        {/* <span
          className="border rounded-full p-2 cursor-pointer hover:bg-black"
          onClick={() => handleEdit('facebook')}
        >
          <FacebookIcon className="text-white" />
        </span>
        <span
          className="border rounded-full p-2 cursor-pointer hover:bg-black"
          onClick={() => handleEdit('whatsapp')}
        >
          <WhatsAppIcon className="text-white" />
        </span>
        <span
          className="border rounded-full p-2 cursor-pointer hover:bg-black"
          onClick={() => handleEdit('twitter')}
        >
          <XIcon className="text-white" />
        </span>
        <span
          className="border rounded-full p-2 cursor-pointer hover:bg-black"
          onClick={() => handleEdit('linkedin')}
        >
          <LinkedInIcon className="text-white" />
        </span> */}
      </div>

      {/* Modal for Editing Links */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Edit Social Media Link"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-xl font-bold mb-4">Edit {selectedIcon} Link</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Enter New Link:</label>
          <input
            type="text"
            value={selectedLink}
            onChange={(e) => setSelectedLink(e.target.value)}
            className="text-input mb-2"
          />
        </div>
        <img src={image} className='mb-2' alt='img' height={40} width={40}/>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Upload Images</label>
          <input
            type="file"
            onChange={(e) => handleImageChange(e)}
            className="text-input mb-2"
          />
          
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Change social media name</label>
          <input
          value={socialName}
            type="text"
            onChange={(e) => setsocialName(e.target.value)}
            className="text-input mb-2"
          />
          
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleSave}
            className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Save
          </button>
          <button
            onClick={() => handleCancel()}
            className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
}
