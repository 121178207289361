import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StatisticalAnalysisPage = () => {
  const data = [
    { name: 'January', earnings: 65 },
    { name: 'February', earnings: 59 },
    { name: 'March', earnings: 80 },
    { name: 'April', earnings: 81 },
    { name: 'May', earnings: 56 },
    { name: 'June', earnings: 55 },
    { name: 'July', earnings: 40 },
  ];

  return (
    <div className="min-h-screen bg-background text-white p-4">
      <h1 className="text-4xl font-bold text-primary mb-4">Statistical Analysis</h1>
      <div className="bg-gray-800 p-4 rounded">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="earnings" fill="rgba(255, 99, 132, 0.6)" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default StatisticalAnalysisPage;
