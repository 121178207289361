import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import slide2 from '../../../assets/img/slide2.png';
import { SketchPicker } from 'react-color';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axiosInstance from '../../../features/auth/api';
import { apiEndpoint } from '../../../config/constant';
import { useSelector } from 'react-redux';
import { Slide, toast } from 'react-toastify';
import ToastContent from '../../../HealperComponents/toast';

Modal.setAppElement('#root'); // Ensures accessibility for the modal

const WhyUsSection = () => {


  const { data, status, error } = useSelector((state) => state.HomePageInfo);

  // console.log(JSON.parse(data[0]?.sectionThree),'section threee');



  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState('START PLAYING ONLINE POKER WITH EASE');
  const [description, setDescription] = useState(
    'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem.'
  );
  const [slides, setSlides] = useState([slide2, slide2, slide2]); // Default slides
  const [newSlides, setNewSlides] = useState([null, null, null]);
  const [textColor, setTextColor] = useState('#ffffff');
  const [headingColor, setHeadingColor] = useState('#ffffff');
  const [modalIsOpen, setModalIsOpen] = useState(false);

 
  useEffect(() => {
    if(data){
      const value = JSON.parse(data[0]?.sectionThree);
      setSlides(value?.slide)
      setTitle(value?.content?.title);
      setDescription(value?.content?.description);
      setTextColor(value?.content?.textColor);
      setHeadingColor(value?.content?.headingColor);
    }

  },[data])


  const handleSlideChange = async (index, e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('files', file);
    try {
      const response = await axiosInstance.post(apiEndpoint.UPLOADIMAGES, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
              toast.success(<ToastContent message={"Save Successfull"} status="success" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
      const updatedSlides = [...slides];
      updatedSlides[index] = response?.data?.data?.imageURL;
      setSlides(updatedSlides);
    } catch (error) {
      console.error(error);
      alert('Failed to upload image');
    }
  }


  const handleSave = async () => {
    const formData = new FormData();


    const content = {
      title: title,
      description:description,
      textColor:textColor,
      headingColor:headingColor,

    }

    const data = {
      content: content,
      slide : slides,
    }
    // newSlides.forEach((slide, index) => {
    //   if (slide) {
    //     formData.append(`slide${index}`, slide);
    //   }
    // });
    // formData.append('title', title);
    // formData.append('description', description);
    // formData.append('textColor', textColor);

    try {
      await axiosInstance.post(apiEndpoint.UPDATESECTIONTHREE, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // fetchContent();
      setModalIsOpen(false);
    } catch (err) {
      console.error(err);
      alert('Failed to save changes');
    }
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <div className=" flex flex-col items-center h-auto py-5 my-10 bg-gradient-to-r from-[#9e4fff22] via-purple-900 to-[#8b2cff22] group ">
      <p className="text-2xl md:text-3xl font-bold text-yellow-500 text-center" style={{ color: headingColor }}>{title}</p>
      <p className="w-[100%] md:w-[45%] text-center text-white text-sm" style={{ color: textColor }}>{description}</p>
      <div className="flex justify-center items-center my-10">
        <div className=" flex items-center justify-center w-[400px] sm-w-[200px]  mt-4 h-[300px]"> {/* Set a fixed height and width */}
          <Carousel showThumbs={false} showStatus={false} infiniteLoop autoPlay>
            {slides.map((slide, index) => (
              <div key={index} className="w-full  h-full">
                <img src={slide} className="w-full h-full object-cover rounded-lg" alt={`Slide ${index + 1}`} />
              </div>
            ))}
          </Carousel>
    
        </div>
      </div>

      <button
        className=" top-0 right-0 m-4 px-4 py-2 z-50 bg-black text-white rounded opacity-0 transition-opacity duration-300 group-hover:opacity-100"
        onClick={openModal}
      > 
        Edit
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Why Us Section"
        className="modal-content h-[70%]"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-xl font-bold mb-4">Edit Why Us Section</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="text-input mb-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Title color</label>
          <SketchPicker
            color={headingColor}
            onChangeComplete={(color) => setHeadingColor(color.hex)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="text-input mb-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Text Color</label>
          <SketchPicker
            color={textColor}
            onChangeComplete={(color) => setTextColor(color.hex)}
          />
        </div>
  
        {slides.map((slide,index) => (
          <div className="mb-4" key={index}>
            <label className="block text-sm font-medium text-gray-700">Slide Image {index + 1}</label>
            <input
              type="file"
              onChange={(e) => handleSlideChange(index, e)}
              className="file-input mt-4"
            />
            <img src={slide} className="h-12 w-20 mt-1 " alt={`Slide ${index + 1}`} />
          </div>
        ))}
        <button onClick={handleSave} className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Save</button>
        <button onClick={closeModal} className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Cancel</button>
      </Modal>
    </div>
  );
};

export default WhyUsSection;
