// slices/balanceSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from './api';
import { apiEndpoint } from '../../config/constant';



const BASEURL = process.env.REACT_APP_API_URL;

// export const fetchingHomePageInfo2 = async () => {
//     axiosInstance
//       .get("/admin/customisation/home-info")
//       .then((res) => {
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }



export const fetchingHomePageInfo = createAsyncThunk('fetchingWebsiteInfo/fetchingHomePageInfo', async () => {
  const response = await axiosInstance.get(`${BASEURL}/admin/customisation/home-info`);
  return response.data.data;
});
 


const fetchingWebsiteInfo = createSlice({
  name: 'HomeInfo',
  initialState: {
    data: [],
    sectionOne: [
      { heading: '', rewardText: '', img: '', textColor: '#ffffff', headingColor: '#ffffff', fontSize: '24px', fontStyle: 'normal' },
      { heading: '', rewardText: '', img: '', textColor: '#ffffff', headingColor: '#ffffff', fontSize: '24px', fontStyle: 'normal' },
      { heading: '', rewardText: '', img: '', textColor: '#ffffff', headingColor: '#ffffff', fontSize: '24px', fontStyle: 'normal' },
    ],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(fetchingHomePageInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchingHomePageInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        // sectionOne = JSON.stringify(action.payload.sectionOne);
      })
      .addCase(fetchingHomePageInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default fetchingWebsiteInfo.reducer;
