import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import { motion } from 'framer-motion';
import { FaBars } from 'react-icons/fa';

const Layout = ({ component }) => {
  const [isActive, setIsActive] = useState(false);

  function handleToggle() {
    setIsActive(!isActive);
  }

  return (
    <div className="flex bg-black min-h-screen relative">
      <React.Fragment>
        <motion.div
          className="bg-black"
          initial={{ width: 0 }}
          animate={{ width: isActive ? 250 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <Sidebar isActive={isActive} setIsActive={setIsActive} />
        </motion.div>
        <motion.div
          className="w-full h-full p-4"
          initial={{ marginLeft: 0 }}
          animate={{ marginLeft: isActive ? 50 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <span onClick={handleToggle} className="absolute top-4 left-4 text-gray-600 cursor-pointer">
            {!isActive && (
              <span className='bg-white h-10 w-10 rounded-full flex justify-center items-center shadow-2xl fixed shadow-black'>
                <FaBars className='text-2xl text-center z-50' />
              </span>
            )}
          </span>
          {component}
        </motion.div>
      </React.Fragment>
    </div>
  );
};

export default Layout;
