import axios from "axios";
import Cookies from 'js-cookie';

// export const baseURL1 = "http://192.168.29.84:4000/";
export const baseURL1 = process.env.REACT_APP_API_URL || "http://localhost:3500";

const axiosInstance = axios.create({
  baseURL: baseURL1,
});
const token = Cookies.get('token');

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `${token}`
      
    };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    return err.response;
  }
);

export default axiosInstance;
