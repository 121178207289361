// src/pages/Dashboard.js
import React,{useState,useEffect} from 'react';
import axiosInstance from '../../features/auth/api';
import { apiEndpoint } from '../../config/constant';



const Dashboard = () => {


  const [count, setCount] = useState();

  
useEffect(() => {
  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get(apiEndpoint.GETUSERCOUNT);
      const { counts } = response.data.data;
      setCount(counts);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  fetchUsers();
}, []);
  return (

      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-y-auto p-6 bg-gray-100">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">Users</h2>
              <p>Total Users: {count}</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">Revenue</h2>
              <p>Total Revenue: $123,456</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">Performance</h2>
              <p>Website Traffic: 5678</p>
            </div>
          </div>
        </main>
      </div>

  );
};

export default Dashboard;
