
import WithdrawalRequestPage from '../components/WithdrawalRequestPage';
import DepositPage from '../components/DepositPage';
import ActiveUsersPage from '../components/ActiveUsersPage';
import StatisticalAnalysisPage from '../components/StatisticalAnalysisPage';
import WebsiteEditingPage from '../components/WebsiteEditingPage';
import Dashboard from '../components/Dashboard'
import GameEdit from '../components/GameEdit';


const routes = [
    {
      id: 1,
      path: "/website-editing",
      element: <WebsiteEditingPage />,
    },
    {
      id: 2,
      path: "/statistical-analysis",
      element: <StatisticalAnalysisPage />,
    },
    {
      id: 3,
      path: "/active-users",
      element: <ActiveUsersPage />,
    },
    {
      id: 4,
      path: "/withdrawal-requests",
      element: <WithdrawalRequestPage />,
    },
    {
      id: 5,
      path: "/deposits",
      element: <DepositPage />,
    },
    {
      id: 6,
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      id: 6,
      path: "/game-setting",
      element: <GameEdit />,
    },
  ];
  export default routes;