
import React from 'react';

const DepositPage = () => {
  // Mock data
  const deposits = [
    { id: 1, user: 'user1', amount: 100 },
    { id: 2, user: 'user2', amount: 200 },
    // Add more mock deposits here
  ];

  return (
    <div className="min-h-screen bg-background text-white p-4">
      <h1 className="text-4xl font-bold text-primary mb-4">Deposits</h1>
      <div className="bg-gray-800 p-4 rounded">
        <table className="w-full text-left">
          <thead>
            <tr>
              <th className="p-2">ID</th>
              <th className="p-2">User</th>
              <th className="p-2">Amount</th>
            </tr>
          </thead>
          <tbody>
            {deposits.map((deposit) => (
              <tr key={deposit.id}>
                <td className="p-2">{deposit.id}</td>
                <td className="p-2">{deposit.user}</td>
                <td className="p-2">${deposit.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DepositPage;
