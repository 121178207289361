import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../features/auth/api';
import { apiEndpoint } from '../../config/constant';

const ActiveUsersPage = () => {
  const [activeUsers, setActiveUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get(apiEndpoint.GETUSER);
        const { alluser, activeUser } = response.data.data;
        setAllUsers(alluser);
        setActiveUsers(activeUser);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="min-h-screen bg-background text-white p-4">
      <div>
        <h1 className="text-4xl font-bold text-primary mb-4">Active Users</h1>
        <div className="bg-gray-800 p-4 rounded">
          <table className="w-full text-left">
            <thead>
              <tr>
                <th className="p-2">ID</th>
                <th className="p-2">Username</th>
                <th className="p-2">User Address</th>
                <th className="p-2">Wallet Type</th>
                <th className="p-2">Profile URL</th>
              </tr>
            </thead>
            <tbody>
              {activeUsers?.map((user) => (
                <tr key={user.id}>
                  <td className="p-2">{user.id}</td>
                  <td className="p-2">{user.userName}</td>
                  <td className="p-2">{user.userAddress}</td>
                  <td className="p-2">{user.walletType}</td>
                  <td className="p-2">
                    <a href={user.profileUrl} target="_blank" rel="noopener noreferrer">
                      Profile
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      
      <div className="mt-8">
        <h1 className="text-4xl font-bold text-primary mb-4 ">All Users</h1>
        <div className="bg-gray-800 p-4 rounded overflow-auto">
          <table className="w-full text-left">
            <thead>
              <tr>
                <th className="p-2">ID</th>
                <th className="p-2">Username</th>
                <th className="p-2">User Address</th>
                <th className="p-2">Wallet Type</th>
                <th className="p-2">Profile URL</th>
              </tr>
            </thead>
            <tbody>
              {allUsers?.map((user) => (
                <tr key={user.id}>
                  <td className="p-2">{user.id}</td>
                  <td className="p-2">{user.userName}</td>
                  <td className="p-2">{user.userAddress}</td>
                  <td className="p-2">{user.walletType}</td>
                  <td className="p-2">
                    <a href={user.profileUrl} target="_blank" rel="noopener noreferrer">
                      Profile
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ActiveUsersPage;
