import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminLoginPage from './components/AdminLoginPage';
import Layout from './Layout';
import routes from './Routes';
import { AllData } from './Context/PageContext';
import { ToastContainer } from 'react-toastify';


function App() {
  return (
    <Router>
        <AllData>
      <ToastContainer />
        <div className=" bg-black">
          <Routes>
            {console.log(routes)}
            <Route path="/" element={<AdminLoginPage />} />
            {routes.map((route) => {
              return (
                <Route
                  key={route.id}
                  path={route.path}
                  element={<Layout component={route.element} />}
                />
              );
            })}
          </Routes>
        </div>
    </AllData>
      </Router>
  );
}

export default App;
