import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import fetchingWebsiteInfo from "./fetchingWebsiteInfo"

const store = configureStore({
  reducer: {
    HomePageInfo : fetchingWebsiteInfo
  },
});

export default store;
